import * as React from 'react'
import { useEffect, useState, ComponentProps, FunctionComponent } from 'react'
import {
  IoChevronForward as NextArrowIcon,
  IoChevronBack as PreviousArrowIcon
} from 'react-icons/io5'

type CarouselArrowsProps = {
  next?: any
  previous?: any
  carouselState?: any
}

const CarouselArrowButtons: FunctionComponent<CarouselArrowsProps> = (
  props
) => {
  const {
    next,
    previous,
    carouselState: { currentSlide, slidesToShow, totalItems }
  } = props

  const customArrowButtonStyles =
    'rounded-full w-10 h-10 flex flex-wrap content-center place-content-center bg-white transition-colors text-2xl border border-solid'
  const customArrowButtonActiveStyles =
    'cursor-pointer hover:bg-gray-100 border-alpha-oatmeal-30 hover:border-alpha-oatmeal-60 focused:border-alpha-oatmeal-60 text-alpha-black'
  const customArrowButtonDisabledStyles =
    'cursor-default border-alpha-oatmeal-10 text-alpha-oatmeal-10'
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState(true)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [showArrows, setShowArrows] = useState(true)

  useEffect(() => {
    setIsPreviousButtonDisabled(currentSlide === 0)
  }, [currentSlide])

  useEffect(() => {
    setIsNextButtonDisabled(totalItems === currentSlide + slidesToShow)
  }, [slidesToShow, totalItems, currentSlide])

  useEffect(() => {
    setShowArrows(totalItems > slidesToShow)
  }, [slidesToShow, totalItems])

  if (!showArrows) {
    return null
  }

  return (
    <div className='absolute top-0 right-0 flex gap-3 text-xl pb-2 pr-2'>
      <div
        role='button'
        className={`
          ${customArrowButtonStyles} ${
            isPreviousButtonDisabled
              ? customArrowButtonDisabledStyles
              : customArrowButtonActiveStyles
          }
        `}
        onClick={previous}
        data-testid='button-carouselPreviousArrow'
      >
        <PreviousArrowIcon className='relative right-[1px]' />
      </div>
      <div
        role='button'
        className={`
          ${customArrowButtonStyles} ${
            isNextButtonDisabled
              ? customArrowButtonDisabledStyles
              : customArrowButtonActiveStyles
          }
        `}
        onClick={next}
        data-testid='button-carouselNextArrow'
      >
        <NextArrowIcon className='relative left-[1px]' />
      </div>
    </div>
  )
}

export { CarouselArrowButtons }
