import * as React from 'react'
import { FunctionComponent, useContext } from 'react'
import {
  MenuButton,
  Menu,
  MenuItem,
  useMenuStore,
  MenuSeparator
} from '@ariakit/react'

import { IoChevronDown as ChevronDownIcon } from 'react-icons/io5'
import { getAudioLanguage, getSubtitleLanguage } from './video-player-utils'
import { ComponentWrapperContext } from '../context'

type VideoPlayerModalDownloadOptionsDropdownProps = {
  options: any[]
  value: string
  clickHandler: (input: any) => void
  isLanguageSelector?: boolean
}

const VideoPlayerModalDownloadOptionsDropdown: FunctionComponent<
  VideoPlayerModalDownloadOptionsDropdownProps
> = ({ options, value, clickHandler, isLanguageSelector = false }) => {
  const { localeTranslate } = useContext(ComponentWrapperContext)
  const menu = useMenuStore({ placement: 'bottom' })

  if (!options) {
    return <p>Loading..</p>
  }

  const formatLanguageLabel = (stringifiedJSON) => {
    try {
      const { audio, subtitle } = JSON.parse(stringifiedJSON)

      const translatedAudioLabel = localeTranslate(
        getAudioLanguage(audio),
        audio
      )

      if (!subtitle) {
        return $localize`:video.download.options|Audio Language Label@@audioLanguageLabel:${translatedAudioLabel} audio`
      }
      const translatedSubtitleLabel = localeTranslate(
        getSubtitleLanguage(subtitle),
        subtitle
      )

      return $localize`:products.video|Product available in audio with subtitles@@productSubtitledAudio:${translatedAudioLabel} audio with ${translatedSubtitleLabel} subtitles`
    } catch (error) {
      return stringifiedJSON
    }
  }

  return options && options.length > 1 ? (
    <>
      <MenuButton
       data-testid={isLanguageSelector ?'button-downloadVideoLang':'button-downloadVideoRendition'}
        store={menu}
        className='bg-white flex min-h-12 px-4 py-2 text-left justify-between items-center rounded border-2 border-alpha-oatmeal-10 border-solid font-body text-alpha-black'
      >
        {isLanguageSelector ? formatLanguageLabel(value) : value}

        <ChevronDownIcon size={24} />
      </MenuButton>
      <Menu
        store={menu}
        className='bg-white rounded border-2 border-alpha-oatmeal-10 shadow-xl z-50 py-1 max-h-[320px] overflow-auto'
        sameWidth={true}
      >
        {options.map(({ value, label }, idx) => {
          return (
            <React.Fragment key={value + idx}>
              {idx ? <MenuSeparator className='my-1' /> : null}
              <MenuItem
                className='flex min-h-[32px] px-4 py-2 items-center hover:cursor-pointer hover:bg-alpha-white-tint-b leading-tight'
                onClick={() => clickHandler(value)}
                data-testid='dropDown-selectDownloadProperty'
              >
                {isLanguageSelector ? formatLanguageLabel(value) : label}
              </MenuItem>
            </React.Fragment>
          )
        })}
      </Menu>
    </>
  ) : null
}

export default VideoPlayerModalDownloadOptionsDropdown
